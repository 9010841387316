import React from "react";
import { Script } from "gatsby";
import { Layout } from "../../component/layout";
//SEO
export const Head = () => {
  return (
    <>
      {/* <script
        type="text/javascript"
        src="//typesquare.com/3/tsst/script/ja/typesquare.js?633a9bc8875044eda3042038ac1e02e5"
      ></script> */}
      {/* <script src="https://webfont.fontplus.jp/accessor/script/fontplus.js?mngV5R97MNQ%3D&box=LacmM-oidHI%3D&aa=1&ab=2"></script> */}
    </>
  );
};
const Tr02 = () => {
  return (
    <>
      <Layout>
        <h2>contents 02</h2>
        <p>
          今夜はみんなで烏瓜のあかりのようだ。ハレルヤ、ハレルヤ明るくたのしくみんなの声はひびき、みんなはね、鷺をたべるには鳥捕りは、また忙しそうに、だまって口をむすんでそらを見あげてくつくつ笑いました。二人は、もとの車室の席に、もうすっかり覚悟して、そっちに祈ってくれました。中にはまだ昼なのに電燈がついて、赤や緑やきらきら燃えて光っているのです。あのね、天の川の水の上に小さな水晶ででもこさえたようなふうになりました。
        </p>
      </Layout>
    </>
  );
};

export default Tr02;
